import type { Site } from '@/types';

/**
 * Retrieves the first Venue in the array of venues associated with a Site.
 */
export function getSiteVenue(site: Site) {
  if (!site.venues || site.venues.length === 0) {
    throw new Error('A Site must have at least one associated Venue');
  }

  return site.venues[0];
}
