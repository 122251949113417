import { FontData } from '@/types';

export function deriveGoogleFontUrl(data: FontData[] | null): string | null {
  if (data === null || (Array.isArray(data) && data.length === 0)) {
    return null;
  }

  // Returns a `&` separated string of font query parameters
  const fontParams = data
    .filter(
      ({ name, weights }) =>
        name && weights && name !== null && weights !== null,
    )
    .map(({ name, weights }) => {
      // Convert array to e.g. `200;300;400`
      const wght = weights!.map(({ size }) => size).join(';');

      // Replace whitespace with + signs
      const family = name!.replace(' ', '+');

      return `family=${family}:wght@${wght}`;
    })
    .join('&');

  return `https://fonts.googleapis.com/css2?${fontParams}&display=swap`;
}
