import { Site, Event, Venue, VenueAddress } from '@/types';
import { getSiteVenue, getVenueStreet, isNull } from '@/lib/utils';

interface JsonLdObject {
  [key: string]: string | JsonLdObject | Array<string>;
}

function getPostalAddress(address: VenueAddress | null) {
  // Required items for a valid PostalAddress
  const street = getVenueStreet(address);
  if (!address || !address.city || !address.country || !street) {
    return null;
  }

  const jsonAddress: JsonLdObject = {
    '@type': 'PostalAddress',
    addressCountry: address.country,
    addressLocality: address.city,
    streetAddress: street,
  };

  if (address.state) {
    jsonAddress.addressRegion = address.state;
  }
  if (address.postal) {
    jsonAddress.postalCode = address.postal;
  }

  return jsonAddress;
}

/**
 * Structured Type creation function for an Event
 * @see https://developers.google.com/search/docs/appearance/structured-data/event#structured-data-type-definitions
 */
export function getEventJsonLd({ site, event }: { site: Site; event: Event }) {
  const venue = getSiteVenue(site);
  const address = getPostalAddress(venue.address);

  // Required properties for a valid Event
  if (!address || !venue.name || !event.name || !event.start) {
    return null;
  }

  const location: JsonLdObject = {
    '@type': 'Place',
    name: venue.name,
    address,
  };

  const eventJson: JsonLdObject = {
    '@context': 'http://schema.org',
    '@type': 'Event',
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    name: event.name,
    startDate: event.start,
    location,
  };

  if (event.url_image) {
    eventJson.image = [event.url_image];
  }

  if (site.url) {
    eventJson.offers = {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      url: `${site.url}/e/${event.id}`,
    };
  }

  return eventJson;
}

export function getEventsJsonLd({
  events,
  site,
}: {
  events: Event[];
  site: Site;
}) {
  return events
    .map((event) => getEventJsonLd({ event, site: site }))
    .filter((jsonLd) => !isNull(jsonLd));
}

export function getVenueJsonLd(site: Site) {
  const venue = getSiteVenue(site);
  const address = getPostalAddress(venue.address);

  // Required properties for a valid NightClub
  if (!address || !venue.name) {
    return null;
  }

  const venueJson: JsonLdObject = {
    '@context': 'http://schema.org',
    '@type': 'NightClub',
    address,
    name: venue.name,
    paymentAccepted: 'Visa, Master Card, Discover, Amex, ApplePay, AndroidPay',
  };

  if (site.url) {
    venueJson.url = site.url;
  }
  if (site.email) {
    venueJson.email = site.email;
  }

  return venueJson;
}
