/**
 * Formats the street and postal code search query with plus signs and returns
 * a fully formed Google Maps URL
 */
export function createGoogleMapsSearchUrl(searchString: string) {
  const key = 'search';

  // Create a new search param instance
  const params = new URLSearchParams(`${key}=${searchString}`);

  // Remove the `search=` part of the resulting query string to give us just a
  // string with "+" for spaces
  const path = params.toString().replace(`${key}=`, '');

  return `https://www.google.com/maps/search/${path}`;
}
