import { Container, Nav, Navbar } from 'react-bootstrap';

import { useSite } from '@/contexts/siteContext';

import type { HeaderProps } from './types';

const DcHeader = ({ activePath, navItems, variant }: HeaderProps) => {
  const { name: venueName, url_logo } = useSite();

  return (
    <Navbar variant={variant} expand="md" fixed="top">
      <Container>
        {url_logo && (
          <Navbar.Brand href="/">
            <img src={url_logo} height="55" alt={`${venueName} logo`} />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="navMenu" />
        <Navbar.Collapse id="navMenu">
          <Nav
            className="font-header fw-bold fs-5 text-uppercase ms-md-auto"
            as="ul"
          >
            {navItems.map((navItem) => (
              <Nav.Item key={navItem.href!} as="li">
                <Nav.Link
                  href={navItem.href!}
                  active={navItem.href! === activePath}
                >
                  {navItem.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DcHeader;
