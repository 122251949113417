import { VenueAddress } from '@/types';

export function getVenueStreet(address: VenueAddress | null): string | null {
  if (!address) {
    return null;
  }

  const { identifier, street_number, street_name, street_type } = address;

  let street = '';

  if (street_number) {
    street += street_number;
  }
  if (street_name) {
    street += ` ${street_name}`;
  }
  if (street_type) {
    street += ` ${street_type}`;
  }
  if (identifier) {
    street += ` ${identifier}`;
  }

  return street.trim() || null;
}
