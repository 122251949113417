import React from 'react';

import { useSite } from '@/contexts/siteContext';
import {
  createGoogleMapsSearchUrl,
  formatPhoneNumber,
  getSiteVenue,
  getVenueStreet,
} from '@/lib/utils';

const Address = ({ classNames = [] }: { classNames?: Array<string> }) => {
  const site = useSite();
  const venue = getSiteVenue(site);
  const { email } = site;
  const { address, phone } = venue;

  if (!address) {
    return null;
  }

  const { city, state, postal } = address;
  const street = getVenueStreet(address);

  if (!street || !state || !postal || !city) {
    return null;
  }

  const formattedPhone = formatPhoneNumber(phone);

  return (
    <address className={['address', ...classNames].join(' ')}>
      <a
        href={createGoogleMapsSearchUrl(`${street} ${postal}`)}
        target="_blank"
        rel="noopener"
      >
        {street}
      </a>
      <br />
      {city}, {state} {postal}
      {formattedPhone && (
        <React.Fragment>
          <br />
          <a href={`tel:${formattedPhone}`}>{formattedPhone}</a>
        </React.Fragment>
      )}
      {email && (
        <React.Fragment>
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </React.Fragment>
      )}
    </address>
  );
};

export default Address;
