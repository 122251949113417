import { COLOR_MODES, THEME_NAMES } from '@/constants';
import { useSite } from '@/contexts/siteContext';
import type { FontData } from '@/types';

function deriveFontCSSVariables(fontData: FontData[] | null) {
  // Defaults for no declared fonts
  if (fontData === null) {
    return `
      --theme-body-font: sans-serif;
      --theme-headings-font: serif;
    `;
  }

  return fontData
    .map(({ name, type }) => `--theme-${type}-font: ${name}, sans-serif;`)
    .join('\n');
}

const cssVarNames = {
  backgroundColor: '--theme-bg-rgb',
  textColor: '--theme-text-rgb',
  accentColor: '--theme-accent-rgb',
};

const themeCSSVars = {
  [THEME_NAMES.FLORIDA]: {
    [COLOR_MODES.light]: {
      [cssVarNames.backgroundColor]: '255, 255, 255',
      [cssVarNames.textColor]: '40, 40, 43',
    },
    [COLOR_MODES.dark]: {
      [cssVarNames.backgroundColor]: '41, 43, 44',
      [cssVarNames.textColor]: '220, 220, 221',
    },
  },
  [THEME_NAMES.DC]: {
    [COLOR_MODES.dark]: {
      [cssVarNames.backgroundColor]: '14, 5, 41',
      [cssVarNames.textColor]: '225, 225, 225',
      [cssVarNames.accentColor]: '175, 148, 92',
    },
  },
};

function getThemeCSSVariables(
  themeName: string | null,
  colorMode: string | null,
) {
  const defaultTheme = themeCSSVars[THEME_NAMES.FLORIDA][COLOR_MODES.dark];

  if (!themeName || !colorMode) {
    return defaultTheme;
  }

  const theme = themeCSSVars[themeName]?.[colorMode];
  if (!theme) {
    return defaultTheme;
  }

  return Object.entries(theme)
    .map(([name, value]) => `${name}: ${value};`)
    .join('\n');
}

const GlobalStyles = () => {
  const site = useSite();
  const { background_rgb, color_mode, fonts, theme_name, url_background } =
    site;
  const backgroundVar = url_background
    ? `--theme-body-bg-url: url(${url_background});`
    : '';
  const backgroundRGBOverride = background_rgb
    ? `${cssVarNames.backgroundColor}: ${background_rgb};`
    : '';
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
      :root {
        ${backgroundVar}
        ${deriveFontCSSVariables(fonts)}
        ${getThemeCSSVariables(theme_name, color_mode)}
        ${backgroundRGBOverride}
      }
    `,
      }}
    />
  );
};

export default GlobalStyles;
