import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useSite } from '@/contexts/siteContext';
import { getNavItems, getThemeValue, isNull } from '@/lib/utils';
import { THEME_NAMES } from '@/constants';

import DcHeader from './dcHeader';
import FloridaHeader from './floridaHeader';

const ThemeHeaders = {
  [THEME_NAMES.DC]: DcHeader,
  [THEME_NAMES.FLORIDA]: FloridaHeader,
  default: FloridaHeader,
};

const Header = () => {
  const { asPath: currentPath, isReady } = useRouter();
  const { navigation, theme_name, color_mode } = useSite();

  // Safely set the bootstrap variant based on our color_mode
  const variant = isNull(color_mode) ? 'dark' : color_mode!;

  // Filter the nav items
  const navItems = getNavItems(navigation);

  // The `asPath` key on the router will update during client-side runtime which
  // causes a render mismatch unless we respond to the `isReady` flag
  const [activePath, setActivePath] = useState('/');
  useEffect(() => {
    if (isReady) {
      setActivePath(currentPath);
    }
  }, [isReady, currentPath]);

  const HeaderComponent = getThemeValue(theme_name, ThemeHeaders);

  return (
    <HeaderComponent
      activePath={activePath}
      navItems={navItems}
      variant={variant}
    />
  );
};

export default Header;
