/**
 * Takes a phone number string in the form of "+012028278791" and converts it
 * into the format "202-827-8791"
 *
 * @link https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/
 */
export function formatPhoneNumber(phone?: string | null) {
  if (!phone) {
    return null;
  }

  //Filter only numbers from the input
  let cleaned = ('' + phone).replace(/\D/g, '');

  //Check if the input matches
  let match = cleaned.match(/^(\d{1}|\d{2})?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Tosses away the country code, which is match[1]
    return [match[2], match[3], match[4]].join('-');
  }

  return null;
}
