import { Fragment, useRef, useState } from 'react';
import axios from 'axios';

import { useSite } from '@/contexts/siteContext';

interface InputsProps {
  isError: boolean;
  isSubmitting: boolean;
}

const Inputs = ({ isError, isSubmitting }: InputsProps) => {
  const site = useSite();

  return (
    <Fragment>
      <label htmlFor="footer-email" className="visually-hidden">
        Email address
      </label>
      <div className="input-group input-group-sm justify-content-center">
        <input type="hidden" name="brand" value={site.url_name} />
        <input
          type="email"
          name="email"
          id="footer-email"
          className="form-control footer-email-signup-input"
          placeholder="Type email address"
          required
        />
        <button type="submit" className="btn" disabled={isSubmitting}>
          Subscribe
        </button>
      </div>
      {isError && (
        <div className="text-danger" role="alert">
          We encountered an error. Please try again later.
        </div>
      )}
    </Fragment>
  );
};

const FooterEmailSignup = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmitting(true);
    setIsError(false);

    const body = Object.fromEntries(new FormData(event.currentTarget));

    try {
      await axios.post('/api/contact', body);
      setIsSuccess(true);
      formRef.current?.reset();
    } catch (err) {
      setIsError(true);
    }

    setIsSubmitting(false);
  }

  return (
    <form className="footer-email-signup" onSubmit={handleSubmit} ref={formRef}>
      <span>Sign up for updates</span>
      <br />
      {isSuccess ? (
        <div>Success! Thank you</div>
      ) : (
        <Inputs isSubmitting={isSubmitting} isError={isError} />
      )}
    </form>
  );
};

export default FooterEmailSignup;
