import { useSite } from '@/contexts/siteContext';
import { isNull } from '@/lib/utils';

const FooterNav = () => {
  const { navigation } = useSite();

  if (isNull(navigation)) {
    return null;
  }

  const nav = navigation.filter((navItem) => {
    return (
      navItem?.i18n === 'en-US' &&
      !isNull(navItem.href) &&
      !isNull(navItem.name)
    );
  });

  return (
    <nav className="mb-3">
      <ul className="nav justify-content-center">
        {nav.map((navItem) => (
          <li className="nav-item" key={navItem.href}>
            <a href={navItem.href!} className="nav-link">
              {navItem.name!}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FooterNav;
