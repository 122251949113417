import { getThemeValue } from '@/lib/utils';
import { useSite } from '@/contexts/siteContext';
import { THEME_NAMES } from '@/constants';

import DcFooter from './dcFooter/dcFooter';
import FloridaFooter from './floridaFooter/floridaFooter';

const ThemeFooters = {
  [THEME_NAMES.DC]: DcFooter,
  [THEME_NAMES.FLORIDA]: FloridaFooter,
  default: FloridaFooter,
};

const Footer = () => {
  const { theme_name } = useSite();
  const FooterComponent = getThemeValue(theme_name, ThemeFooters);

  return <FooterComponent />;
};

export default Footer;
