import { isNull } from '@/lib/utils/isNull';

interface ThemeValues {
  [key: string]: any;
  default: any;
}

export function getThemeValue(
  themeName: string | null,
  themeValues: ThemeValues,
) {
  if (isNull(themeName)) {
    return themeValues.default;
  }

  return themeValues[themeName] ?? themeValues.default;
}
