import Link from 'next/link';
import SocialIconLinks from '@/components/socialIconLinks';
import { useSite } from '@/contexts/siteContext';
import { isNull } from '@/lib/utils';

const DcFooter = () => {
  const site = useSite();
  const { name, navigation, url_logo } = site;
  const nav = navigation?.filter((navItem) => {
    return (
      navItem?.i18n === 'en-US' &&
      !isNull(navItem.href) &&
      !isNull(navItem.name)
    );
  });

  return (
    <footer className="footer py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <hr className="my-4" />
          </div>
          <div className="col-md-3">
            {url_logo && (
              <Link href="/">
                <img
                  className="footer-logo d-block mx-auto mb-3"
                  src={url_logo}
                  alt={`${name} logo`}
                />
              </Link>
            )}
          </div>
          <div className="col-md-6">
            {nav && (
              <nav className="mb-3">
                <ul className="nav flex-column">
                  {nav.map((navItem) => (
                    <li className="nav-item text-center" key={navItem.href}>
                      <a
                        href={navItem.href!}
                        className="nav-link font-header text-uppercase fw-bold"
                      >
                        {navItem.name!}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
          <div className="col-md-3">
            <SocialIconLinks />
          </div>
        </div>
        <div className="row">
          <div className="fs-7 my-3 text-center">
            © {new Date().getFullYear()} {name}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DcFooter;
