import Script from 'next/script';
import * as snippet from '@segment/snippet';

import { useSite } from '@/contexts/siteContext';

function renderSnippet(apiKey: string) {
  const opts = {
    apiKey,
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

const SegmentSnippet = () => {
  const { segment: segmentApiKey } = useSite();

  if (!segmentApiKey) {
    console.warn('No Segment key available — analytics will not function!');
    return null;
  }

  return (
    <Script
      id="segment-script"
      dangerouslySetInnerHTML={{ __html: renderSnippet(segmentApiKey) }}
    />
  );
};

export default SegmentSnippet;
