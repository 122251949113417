import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faSoundcloud,
  faSquareFacebook,
  faTwitter,
  faTiktok,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { useSite } from '@/contexts/siteContext';

interface ListItemProps {
  href: string;
  children: React.ReactNode;
  srText: string;
}

const ListItem = ({ children, href, srText }: ListItemProps) => (
  <li className="list-inline-item">
    <a href={href} target="_blank" rel="noopener">
      <span className="visually-hidden">{srText}</span>
      {children}
    </a>
  </li>
);

interface SocialIconLinksProps {
  size?: SizeProp;
}

const SocialIconLinks = ({ size = '1x' }: SocialIconLinksProps) => {
  const { instagram, facebook, soundcloud, tiktok, twitter, youtube } =
    useSite();

  return (
    <ul className="list-inline text-center">
      {instagram && (
        <ListItem
          href={`https://instagram.com/${instagram}`}
          srText="Instagram"
        >
          <FontAwesomeIcon icon={faInstagram} size={size} />
        </ListItem>
      )}
      {facebook && (
        <ListItem href={`https://facebook.com/${facebook}`} srText="Facebook">
          <FontAwesomeIcon icon={faSquareFacebook} size={size} />
        </ListItem>
      )}
      {twitter && (
        <ListItem href={`https://twitter.com/${twitter}`} srText="Twitter">
          <FontAwesomeIcon icon={faTwitter} size={size} />
        </ListItem>
      )}
      {soundcloud && (
        <ListItem
          href={`https://soundcloud.com/${soundcloud}`}
          srText="Soundcloud"
        >
          <FontAwesomeIcon icon={faSoundcloud} size={size} />
        </ListItem>
      )}
      {tiktok && (
        <ListItem href={`https://tiktok.com/${tiktok}`} srText="TikTok">
          <FontAwesomeIcon icon={faTiktok} size={size} />
        </ListItem>
      )}
      {youtube && (
        <ListItem href={`https://youtube.com/${youtube}`} srText="YouTube">
          <FontAwesomeIcon icon={faYoutube} size={size} />
        </ListItem>
      )}
    </ul>
  );
};

export default SocialIconLinks;
