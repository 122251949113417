import Link from 'next/link';

import { getSiteVenue } from '@/lib/utils';
import { useSite } from '@/contexts/siteContext';

import Address from '@/components/address/address';

import FooterNav from './footerNav';
import SocialIconLinks from '@/components/socialIconLinks';
import FooterEmailSignup from './footerEmailSignup';

const FloridaFooter = () => {
  const site = useSite();
  const venue = getSiteVenue(site);
  const { name, url_logo } = site;
  const { address } = venue;
  const { city, state } = address || {};

  const copyrightName = city && state ? `${name} ${city} ${state}` : name;

  return (
    <footer className="footer py-3">
      <div className="container">
        {url_logo && (
          <Link href="/">
            <img
              className="footer-logo d-block mx-auto"
              src={url_logo}
              alt={`${name} logo`}
            />
          </Link>
        )}
        <FooterNav />
        <Address classNames={['text-center']} />
        <SocialIconLinks />
        <div className="row">
          <div className="col-6 text-center">
            Copyright © {new Date().getFullYear()}
            <br />
            {copyrightName}. All Rights Reserved
          </div>
          <div className="col-6 text-center">
            <FooterEmailSignup />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FloridaFooter;
