import { useSite } from '@/contexts/siteContext';
import { getVenueJsonLd } from '@/lib/json-ld';
import { isNull } from '@/lib/utils';

const VenueRichSnippet = () => {
  const site = useSite();

  const snippet = getVenueJsonLd(site);

  if (isNull(snippet)) {
    return null;
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(snippet) }}
    />
  );
};

export default VenueRichSnippet;
