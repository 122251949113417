import React from 'react';
import Head from 'next/head';

import Header from '@/components/header/header';
import Footer from '@/components/footer/footer';
import PageHeader from './pageHeader';
import SegmentSnippet from './segmentSnippet';
import VenueRichSnippet from '@/components/richSnippets/venue';
import { isNull } from '@/lib/utils';
import { useSite } from '@/contexts/siteContext';
import { COLOR_MODES } from '@/constants';
import { deriveGoogleFontUrl } from './helpers';
import GlobalStyles from './globalStyles';
import { THEME_NAMES } from '@/constants';

/**
 * Grab the className we want for each theme. Default to `dark` in case of an unknown key.
 */
function getColorModeClass(colorMode: string | null) {
  const mode = isNull(colorMode) ? COLOR_MODES.dark : COLOR_MODES[colorMode];
  return `theme-${mode}`;
}

function getThemeClass(themeName: string | null) {
  const name = isNull(themeName) ? THEME_NAMES.FLORIDA : themeName;
  return `theme-${name}`;
}

interface LayoutProps {
  children?: React.ReactNode;
  noBackgroundImage?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
}

const Layout = ({
  children,
  noBackgroundImage,
  withFooter = true,
  withHeader = true,
}: LayoutProps) => {
  const site = useSite();
  const { fonts, color_mode, theme_name, url_favicon } = site;
  const colorModeClass = getColorModeClass(color_mode);
  const themeNameClass = getThemeClass(theme_name);
  const googleFontUrl = deriveGoogleFontUrl(fonts);
  const extraThemeClasses = noBackgroundImage ? 'theme-no-bg' : '';
  const cx = `theme ${colorModeClass} ${themeNameClass} ${extraThemeClasses}`;

  return (
    <div className={cx}>
      <Head>
        {url_favicon && (
          <link rel="icon" type="image/png" sizes="32x32" href={url_favicon} />
        )}
        {googleFontUrl && <link href={googleFontUrl} rel="stylesheet" />}
      </Head>
      <GlobalStyles />
      {withHeader && <Header />}
      <main className="flex-shrink-0">
        <PageHeader />
        <div className="container">{children}</div>
      </main>
      {withFooter && <Footer />}
      <VenueRichSnippet />
      <SegmentSnippet />
    </div>
  );
};

export default Layout;
