import { isNull } from '@/lib/utils/isNull';
import { NavItem } from '@/types';

/**
 * Filter out navigation items that are not in English or do not have an href or name.
 */
export function getNavItems(navigation: NavItem[] | null) {
  const nav = navigation ?? [];
  return nav.filter((navItem) => {
    return (
      navItem?.i18n === 'en-US' &&
      !isNull(navItem.href) &&
      !isNull(navItem.name)
    );
  });
}
