/**
 * Default amount of time in seconds to cache statically generated pages after
 * a request before generating a new version. This is only used if the env var,
 * `ISR_REVALIDATE_TIME`, is not available. When looking to update the ISR time
 * per environment, update the env var in Vercel.
 * @see [Incremental Static Regeneration](https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration)
 */
export const DEFAULT_REVALIDATE_TIME = '3600'; // 60 minutes * 60 seconds = 1 hour

/**
 * The enum-like names of our color modes
 */
export const COLOR_MODES: Record<string, string> = {
  dark: 'dark',
  light: 'light',
};

/**
 * The enum-like names of our theme names
 */
export const THEME_NAMES: Record<string, string> = {
  FLORIDA: 'florida',
  DC: 'dc',
};

export const SITE_TYPES: Record<string, string> = {
  MEMBERSHIP: 'membership',
};

/**
 * Enum-like names for network states
 */
export const NETWORK_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export type NetworkState = (typeof NETWORK_STATES)[keyof typeof NETWORK_STATES];

/**
 * Currency codes that have no decimal places
 */
export const zeroDecimalCurrencyTypes = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];
