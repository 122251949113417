import { zeroDecimalCurrencyTypes } from '@/constants';

function isZeroDecimalCurrency(currencyType: string) {
  return zeroDecimalCurrencyTypes.includes(currencyType);
}

function getAmountToFormat(amount: number, currencyType: string) {
  let amountToFormat = amount;

  if (!isZeroDecimalCurrency(currencyType)) {
    amountToFormat = amount / 100;
  }

  return amountToFormat;
}

export function formatCurrency(
  amount: number,
  currencyType: string,
  locale: string = 'en-US',
  decimals?: number,
) {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyType,
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });

  return formatter.format(getAmountToFormat(amount, currencyType));
}
