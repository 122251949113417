import { createContext, ReactNode, useContext } from 'react';

import type { Site } from '@/types';

export const SiteContext = createContext<Site | null>(null);

export function useSite() {
  const currentSiteContext = useContext(SiteContext);

  if (!currentSiteContext) {
    throw new Error('useSite has to be used within <SiteContext.Provider>');
  }

  return currentSiteContext;
}

interface SiteContextProviderProps {
  children?: ReactNode;
  site: Site;
}

const SiteContextProvider = ({ children, site }: SiteContextProviderProps) => (
  <SiteContext.Provider value={site}>{children}</SiteContext.Provider>
);

export default SiteContextProvider;
