import Link from 'next/link';
import { THEME_NAMES } from '@/constants';
import { useSite } from '@/contexts/siteContext';
import { isNull } from '@/lib/utils';

interface PageHeaderProps {
  logoUrl: string;
  venueName: string | null;
}

const DcPageHeader = () => null;

const FloridaPageHeader = ({ logoUrl, venueName }: PageHeaderProps) => {
  return (
    <div className="mb-3 text-center">
      <Link href="/" className="d-inline-block">
        <img
          src={logoUrl}
          alt={`${venueName} logo`}
          className="d-block"
          style={{ maxHeight: '225px' }}
        />
      </Link>
    </div>
  );
};

const ThemeHeaders = {
  [THEME_NAMES.DC]: DcPageHeader,
  [THEME_NAMES.FLORIDA]: FloridaPageHeader,
  default: FloridaPageHeader,
};

function getHeaderComponent(themeName: string | null) {
  if (isNull(themeName)) {
    return ThemeHeaders.default;
  }

  return ThemeHeaders[themeName] ?? ThemeHeaders.default;
}

const PageHeader = () => {
  const { name: venueName, theme_name, url_logo } = useSite();

  if (!url_logo) {
    return null;
  }

  const HeaderComponent = getHeaderComponent(theme_name);

  return <HeaderComponent logoUrl={url_logo} venueName={venueName} />;
};

export default PageHeader;
